import { NextUIProvider } from "@nextui-org/react";
import { ErrorBoundary, Provider } from "@rollbar/react";
import { ReCaptchaProvider } from "next-recaptcha-v3";
import getConfig from "next/config";
import { Toaster } from "react-hot-toast";

export default function Providers({ children }: { children: React.ReactNode }) {
  const { publicRuntimeConfig } = getConfig();
  const rollbarConfig = {
    accessToken: publicRuntimeConfig.ROLLBAR_CLIENT_TOKEN,
    environment: publicRuntimeConfig.ENV,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      client: {
        javascript: {
          code_version: "1.0.0",
          source_map_enabled: true,
        },
      },
    },
  };

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <NextUIProvider>
          <Toaster position="top-right" />
          <ReCaptchaProvider
            useEnterprise={true}
            reCaptchaKey={publicRuntimeConfig.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
          >
            {children}
          </ReCaptchaProvider>
        </NextUIProvider>
      </ErrorBoundary>
    </Provider>
  );
}
